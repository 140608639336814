<template>
  <el-dialog title="设置催签" :visible.sync="visible" width="30%">
    <el-form ref="form" :model="entity" label-width="80px">
      <el-form-item label="催签周期">
        <el-input-number v-model="entity.Hours" :min="12"></el-input-number>
        小时
      </el-form-item>
      <el-form-item label="是否开启">
        <el-switch v-model="entity.IsRemind"> </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      entity: {},
    };
  },
  methods: {
    open(Id) {
      if (this.$refs.form) this.$refs.form.clearValidate();
      this.$http
        .post("/Base_Manage/Base_Company/GetTheData?id=" + Id, {})
        .then((res) => {
          this.entity = res.Data;
        });
      this.visible = true;
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http
          .post("/Base_Manage/Base_Company/SaveData", this.entity)
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
              this.visible = false;
              this.$emit("setOK", true);
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style>
</style>